exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alykotiratkaisut-js": () => import("./../../../src/pages/älykotiratkaisut.js" /* webpackChunkName: "component---src-pages-alykotiratkaisut-js" */),
  "component---src-pages-aurinkoenergiaratkaisut-js": () => import("./../../../src/pages/aurinkoenergiaratkaisut.js" /* webpackChunkName: "component---src-pages-aurinkoenergiaratkaisut-js" */),
  "component---src-pages-ilmalampopumput-js": () => import("./../../../src/pages/ilmalämpöpumput.js" /* webpackChunkName: "component---src-pages-ilmalampopumput-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kayttoehdot-js": () => import("./../../../src/pages/käyttöehdot.js" /* webpackChunkName: "component---src-pages-kayttoehdot-js" */),
  "component---src-pages-lasku-[id]-js": () => import("./../../../src/pages/lasku/[id].js" /* webpackChunkName: "component---src-pages-lasku-[id]-js" */),
  "component---src-pages-maksu-onnistui-js": () => import("./../../../src/pages/maksu-onnistui.js" /* webpackChunkName: "component---src-pages-maksu-onnistui-js" */),
  "component---src-pages-maksu-peruttu-js": () => import("./../../../src/pages/maksu-peruttu.js" /* webpackChunkName: "component---src-pages-maksu-peruttu-js" */),
  "component---src-pages-ota-yhteytta-js": () => import("./../../../src/pages/ota-yhteyttä.js" /* webpackChunkName: "component---src-pages-ota-yhteytta-js" */),
  "component---src-pages-sopimusehdot-js": () => import("./../../../src/pages/sopimusehdot.js" /* webpackChunkName: "component---src-pages-sopimusehdot-js" */),
  "component---src-pages-tietosuojakaytanto-js": () => import("./../../../src/pages/tietosuojakäytäntö.js" /* webpackChunkName: "component---src-pages-tietosuojakaytanto-js" */),
  "component---src-pages-tilaus-onnistui-js": () => import("./../../../src/pages/tilaus-onnistui.js" /* webpackChunkName: "component---src-pages-tilaus-onnistui-js" */),
  "component---src-pages-valvontakamerat-js": () => import("./../../../src/pages/valvontakamerat.js" /* webpackChunkName: "component---src-pages-valvontakamerat-js" */)
}

